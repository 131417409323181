<template>
    <div style="height:100%;width:100%">
        <div class="login-bg">
            <img src="../assets/bg2.jpg" alt="">
        </div>
        <div class="login-wrap">
            <div class="login-logo">

            </div>

            <div class="login-form">
                <h2>欢迎登录飞象AI数字人</h2>
                <!-- <div class="login-form-input"> -->
                    <label class="input-title"><smile-filled />手机号</label>
                    <a-input class="accout" v-model:value="formData.telephone" placeholder="请输入手机号"></a-input>
                <!-- </div> -->
                <!-- <div class="login-form-input"> -->
                    <label class="input-title"><lock-filled />密码</label>
                    <a-input-password v-model:value="formData.password" placeholder="请输入密码"></a-input-password >
                <!-- </div> -->
                <label class="input-title"><safety-certificate-filled />验证码</label>
                <div class=" verfig-wrap">
                    <div class="input-wrap">
                        <a-input class="accout" v-model:value="state.verify" placeholder="请输入验证码" />
                    </div>
                    <vueImgVerify ref="verifyRef" />

                </div>

                <a-button class="login-btn" type="primary"  @click="toLogin"> 登录 </a-button>

                <p class="isxy">登录即表示您已阅读并同意<span>《服务协议》</span>和<span>《隐私政策》</span></p>
            </div>
        </div>
     
    </div>
</template>

<script>
import vueImgVerify from "../components/verify.vue"; 
import {onMounted, onUnmounted, reactive, ref} from 'vue'
import {useRouter} from "vue-router"
import { message } from 'ant-design-vue';
import { getCurrentInstance } from 'vue'


export default {
    components: {
        vueImgVerify,
    },
    // onMounted(()=>{

    // }),
    setup(){
        onMounted(()=>{
            window.addEventListener('keydown', keyDown)
        })
        const keyDown = (e) => {
            //如果是回车则执行登录方法
            if (e.keyCode == 13) {
                toLogin()
            }
        }
        onUnmounted(() => {
            window.removeEventListener('keydown', keyDown, false)
        })
        // window.addEventListener('keydown', keyDown)
        const router=useRouter()
        const { proxy } = getCurrentInstance()
        // 登录
        var formData=reactive({telephone:"",password:""})
        const verifyRef = ref(null)
        const state = reactive({
            verify: "",
        }); 

        // let keyDown=function(e){
        //     if (e.keyCode == 13 || e.keyCode == 100) {
        //         toLogin()
        //     }
        // }

         let getTitle=function(){
           proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    sessionStorage.setItem("netTitle",res.data.data.data.title)
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // getTitle()
        const toLogin = function() {
            // console.log(verifyRef.value.imgCode);
            // console.log(state.verify.toUpperCase());
            if (verifyRef.value.imgCode == state.verify.toUpperCase()) {
                proxy.$http.post('/pc/login',{telephone:formData.telephone,password:formData.password},{

                }).then((res) => {
                    if(res.data.code==200){
                        sessionStorage.setItem("token",res.data.data.token)
                        sessionStorage.setItem("id",res.data.data.id)
                        router.push({path:"/"})

                    }else{
                        message.error(res.data.message);
                    }
                });
            } else {
                message.error('验证码错误');
            }
        };

        
        return{
            // keyDown,
            toLogin,formData,
            verifyRef,state
        }
    }
}
</script>