<template>
    <div class="all">
        <a-config-provider :locale="locale">
           <div class="aicreat-container">

                <a-flex vertical class="aicreat-all-wrap">

                <div class="aicreat-title bg-card">
                    <div @click="tohome" style="cursor: pointer;">
                        <img src="./../../assets/video.png" alt="">
                        <span class="main-title">飞象AI数字人（短视频）</span> 
                    </div>

                    <div>
                        <span @click="tohome" class="task-btn"> 回到首页</span>
                        <!--span style="margin-right:12px">可使用积分数：{{videoMinute}}</span>
                        <span @click="openTask" class="task-btn"><ContainerFilled style="color:#4e79fa;font-size:15px" /> 任务列表</span-->
                        <a-dropdown v-if="isLogin&&isLogin!=undefined"  placement="bottomRight">
                            <a class="ant-dropdown-link" @click.prevent>
                            <UserOutlined style="color:#4e79fa;font-size:15px" /> <span style="color:#323335">{{username}}<DownOutlined /></span>
                            
                            </a>
                            <template #overlay>
                            <a-menu>
                                <!--a-menu-item>
                                <a href="javascript:;" @click="openMember">会员充值</a>
                                </a-menu-item-->
                                <!--a-menu-item>
                                <a href="javascript:;" @click="quitLogin">退出登录</a>
                                </a-menu-item-->
                            </a-menu>
                            </template>
                        </a-dropdown>
                        <span style="cursor:pointer" @click="quitLogin" v-else>账号登录</span>
                    </div>
                    
                </div>

                <a-flex  class="aicreat-all-wrap">
                    <a-tabs v-model:activeKey="activeKey" @tabClick="handleTabClick">

                    <a-tab-pane v-for="item,i in vipfeeList1.arr" :key="i" :tab="item.name" class="vipfee-warper">
                        
                        <div class="vipfee-box" :class="{ 'box-selected': selectedIndex === item1.id }"
                        @click="selectItem(item1.id,item1.price)"  v-for="item1,i1 in item.userVipfeeList">
                            <div class="vipfee-title" :class="{ 'vipfee-title-selected': selectedIndex === item1.id }">{{ item1.title }}</div>
                            <div class="vipfee-content">
                                <div class="vipfee-discount">
                                    <span>送{{item1.points}}积分</span>
                                    <div></div>

                                </div>
                                <div class="price">{{ item1.price }}元</div>

                            </div>

                        </div>
                    
                    </a-tab-pane>

                    
                </a-tabs>


                

                
                </a-flex>


                <div class="line"></div>

                <div class="payment-choose">

                    <div class="payment">
                        <img src="https://mobvoi-backend-public.ticwear.com/moyin_web/open-api-platform/prd/img/weixin.min.c8f330fe.svg"/>
                        <img src="https://mobvoi-backend-public.ticwear.com/moyin_web/open-api-platform/prd/img/zfb.782af57c.svg"/>
                        <span>微信或支付宝支付</span>
                    </div>

                </div>

                <div class="code">
                    <div class="qrcode">
                        <img :src="orderInfo.data" style="    width: 136px;
    height: 136px;" />
                    </div>
                    <div class="codetxt">
                        <div class="amount">扫码支付 {{showmoney}}元</div>
                        <div class="protocol">
                            购买前请先阅读并同意
                            <a href="#">《订阅会员协议》</a>
                        </div>

                    </div>

                </div>

                </a-flex>



            

           

            </div>
        </a-config-provider>
        </div>
</template>

<style>
.protocol a{
    color: #006eff;
}
.protocol{
    margin-top: 5px;
    font-size: 12px;
    font-family: HarmonyOS_Sans_SC;
    color: #666;
    line-height: 32px;
}
.codetxt .amount{
    margin-top: 34px;
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    color: #333;
    line-height: 32px;
}
.qrcode{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}
.codetxt{
    margin-left: 30px;
}
.code{
    display: flex;
}
.payment img{
    width: 20px;
    height: 18px;
    vertical-align: sub;
}
.payment span{
    height: 30px;
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    color: #333;
    line-height: 30px;
    margin-left: 6px;
}
.payment{
    width: 192px;
    height: 30px;
    border-radius: 8px;
    padding: 6px 0;
    margin-bottom: 22px;
    text-align: center;
    position: relative;
    cursor: pointer;
    width: 192px;
    border: 2px solid #ddd;
}
.line{
    width: 100%;
    height: 1px;
    background-color: #dedede;
    margin-top: 40px;
}
.payment-choose{
    display: flex;
    margin-top: 39px;
}
.vipfee-warper{
    display: flex;
    flex-wrap: wrap;
}
.vipfee-box{
    width: 294px;
    height: 174px;
    background: #fafdff;
    border-radius: 15px;
    border: 2px solid #dfeaf3;
    cursor: pointer;
    margin-top: 29px;
    margin-right: 42px;
}
.box-selected{
    border: 2px solid #006eff;
    border-radius: 15px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background: #f1f7ff;
}
.vipfee-title{
    width: 100%;
    height: 48px;
    background: #dfeaf3;
    border-radius: 12px 12px 0 0;
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC_Medium;
    color: #333;
    line-height: 48px;
    padding-left: 24px;
    box-sizing: border-box;
}
.vipfee-title-selected{
    color: #fff;
    background: #006eff;
}
.vipfee-content{
    padding: 0 24px 18px;
    box-sizing: border-box;
}
.vipfee-discount{
    font-size: 14px;
    font-family: HarmonyOS_Sans_SC;
    color: #333;
    line-height: 22px;
    height: 22px;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
}
.vipfee-content .price{
    font-size: 28px;
    font-family: HarmonyOS_Sans_SC_Bold;
    color: #f50000;
    line-height: 36px;
    margin-top: 32px;
}

</style>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { reactive, ref } from '@vue/reactivity'
import { getCurrentInstance,computed,toRefs } from 'vue'
import {useRouter} from "vue-router";


export default {
    data () {
        return {
            locale: zhCN,
            noClick: true
        }
    },
   
    setup(){

        const { proxy } = getCurrentInstance()

        let isLogin=ref(sessionStorage.getItem("token"))

        const activeKey = ref('0');

        let selectedIndex = ref('0');
        let showmoney = ref('0');

        let selectItem = function(id,money) {
            selectedIndex.value = id
            showmoney.value = money
            addOrder(id)
        }

        let handleTabClick = function(key) {
            console.log('Tab clicked:', key);
            chicktab(key);
        }

        let chicktab = function(index) {            

            selectedIndex.value = vipfeeList1.arr[index].userVipfeeList[0].id
            showmoney.value = vipfeeList1.arr[index].userVipfeeList[0].price
            addOrder(vipfeeList1.arr[index].userVipfeeList[0].id)

        }

        const router=useRouter()
        let tohome = function() {
            router.push({path:"/"})
        }


        let videoMinute=ref(0)
        let username=ref("个人信息")
        let getUserinfo=function(){
             proxy.$http.post('/pc/info',{},{
                headers:{
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
                }
                }).then((res) => {
                    if(res.data.code==200){

                        videoMinute.value=res.data.data.points
                        username.value=res.data.data.mobile
                        
                    
                    }
            });
        }
        getUserinfo()


        let vipfeeList=''
        let vipfeeList1=reactive({arr:[]})


        proxy.$http.post('/pc/vipfee',{page:1,limit:500},{
            headers:{
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),

            }
        }).then((res) => {
            console.log(res.data.data);
          
            

            vipfeeList=reactive(res.data.data)
            //
            vipfeeList1.arr=vipfeeList

            selectedIndex.value = vipfeeList[0].userVipfeeList[0].id
            showmoney.value = vipfeeList[0].userVipfeeList[0].price
            activeKey.value = 0

            addOrder(vipfeeList[0].userVipfeeList[0].id)
         

        });


        let orderInfo=reactive({data:{}})
        let addOrder=function(id){
        
            proxy.$http.post('/pc/viporder',{id:id},{
            headers:{
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            }
            }).then((res) => {
                if(res.data.code==200){

                orderInfo.data=res.data.data
               
                }else{
                    message.error(res.data.msg);
                }
            });
        }



        return{
            videoMinute,username,isLogin,activeKey,vipfeeList1,selectItem,showmoney,chicktab,handleTabClick,
            selectedIndex,tohome,orderInfo

        }
    }

}


</script>